/*********** Gloabal ************/
body {
  margin: 0;
  -moz-osx-font-smoothing: grayscale;
  /*font-size: 1rem; */
  font-size: 95%;
  font-family: "Amazon Ember", Helvetica, sans-serif;
  font-weight: 200;
}
h1 {
  font-weight: 200;
  font-size: 1.2rem;
  color: #31465f;
}

h2 {
  font-weight: 200;
  font-size: 1.1rem;
  color: #31465f;
}

/*********** Layout ************/
.topnav {
  overflow: hidden;
  background-color: #31465f;
  height: 3.4rem;
  padding-left: 1rem;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1;
}

.topnav h1 {
  color: #e1e4ea;
  display: inline-block;
  padding-left: 1rem;
}

.topnav a {
  float: right;
  margin-right: 1rem;
  padding: 1rem;
  color: #e1e4ea;
  text-decoration: none;
}

.topnav a:hover {
  color: #ff9900;
}

#logo {
  vertical-align: middle;
}

.sidenav {
  height: 100%;
  margin-top: 3.4rem;
  width: 11rem;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  background-color: #e1e4ea;
  overflow-x: hidden;
  padding-top: 2rem;
}

.sidenav a {
  padding: 0.4rem;
  margin-left: 1.3rem;
  text-decoration: none;
  color: #31465f;
  display: block;
}

.sidenav a:hover {
  color: #828282;
}

.main {
  margin: 5rem 2rem 2rem 13rem;
}

.box {
  margin: 2rem;
  padding: 0.5rem;
  border-collapse: collapse;
  box-shadow: 1px 1px 4px 0 rgba(0, 0, 0, 0.15);
}

.header {
  margin: 1rem 1rem 2rem 2rem;
}

.chart {
  max-width: 70%;
  margin: 2rem;
}

/************ TD **************/

th {
  color: #31465f;
  font-weight: 400;
  padding: 0.75rem;
  text-align: left;
  border-bottom: 1px solid #e1e4ea;
}

td {
  padding: 0.75rem;
  text-align: left;
}

.dashboard {
  width: 100%;
  padding-bottom: 1rem;
}

.dashboard td {
  border-bottom: 1px solid #e1e4ea;
}

.summary {
  width: 100%;
}

.final {
  width: 32%;
  padding-bottom: 1rem;
}

.final td {
  border-bottom: 1px solid #e1e4ea;
}

.tablelink {
  color: #31465f;
}

/*********** End TD ************/

form {
  display: grid;
  padding: 1em;
}

input[type="submit"] {
  width: 7rem;
  font-size: 0.8rem;
  font-weight: 400;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  background-color: #ff9900;
  color: #fff;
  text-transform: uppercase;
  padding: 0.7rem 0;
  margin: 1rem 0 0 auto;
}

li {
  display: inline;
  margin: auto 1rem;
  padding: 1rem;
}

.btn-space {
  margin-right: 15px;
  margin-left: 15px;
  margin-bottom: 5px;
}

.divright {
  position: relative;
  float: right;
}

.divleft {
  position: relative;
  float: left;
}

.custom-modal {
  width: 80%;
  max-width: none !important;
}

.rag-red {
  background-color: lightcoral;
}

.rag-transparent {
  background-color: transparent;
}
